import React from 'react'

import { FormContainer, FormButtonsElement } from 'components/shared/Forms'
import RentalDisplay from 'components/Displays/RentalDisplay'
import Card from 'components/shared/Card'
import Button from 'components/shared/Button'

export default ({ rental }) => {
  const { decision_date = null, approved = null } = rental

  const submit = async (e, approved) => {
    e.preventDefault()
    try {
      const error = await props.onDecision(approved)
      if (error) {
        console.error(error)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <FormContainer>
      <Card title="Rental Request">
        <RentalDisplay
          type="rent"
          rental={rental}
          status={approved ? 'Approved' : 'Rejected'}
        />
        {approved === null ? (
          <FormButtonsElement
            submitText="Approve"
            backText="Reject"
            submit={e => submit(e, true)}
            back={e => submit(e, false)}
          />
        ) : approved ? (
          <div>
            <h4>Rental Request Confirmed</h4>
            <div>You will recieve an email confirming your request.</div>
            <div>
              The next step is to coordinate a pick up time with the borrower.
            </div>
          </div>
        ) : (
          <div>
            <h4>Rental Request Rejected</h4>
            <div>
              This request has been rejected. The borrower will be notified.
            </div>
          </div>
        )}
        {rental.renter && (
          <Button big to={`/message?recipent=${rental.renter.id}`}>
            Message the Borrower
          </Button>
        )}
      </Card>
    </FormContainer>
  )
}
