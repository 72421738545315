import React from 'react'
import { navigate } from 'gatsby'

import * as routes from 'constants/routes'
import RentalRequestForm from 'components/RentPage/RentalRequestForm'
import Layout from 'layouts/AuthenticatedPage'
import SEO from 'components/shared/SEO'
import { DatabaseService, AuthService } from 'services'
import DatabasePreloader from 'components/shared/DatabasePreloader'

export class RentalConfirmationPage extends React.Component {
  componentDidMount() {
    if (AuthService.currentUser.uid != this.props.rental.lender.id) {
      console.error('Unauthorized Access To Rental')
      navigate('/search')
    }
  }

  onDecision = async decision => {
    try {
      await DatabaseService.setRentalDecisionById(
        this.props.rental.id,
        decision
      )
    } catch (error) {
      return error
    }
  }

  render() {
    return (
      <RentalRequestForm
        rental={this.props.rental}
        onDecision={this.onDecision}
      />
    )
  }
}

export default ({ location }) => {
  return (
    <DatabasePreloader subscribe={true} map={`rentals/:id`} location={location}>
      {(rental, snap) => (
        <Layout location={location} displayFooter={false} showBeacon={true}>
          <SEO title="Rental Confirmation" />
          <RentalConfirmationPage rental={rental} />
        </Layout>
      )}
    </DatabasePreloader>
  )
}
